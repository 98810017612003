<template>
  <v-dialog v-if="this.$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="700px" scrollable>
    <v-card v-if="selectedItem !== null || skipAmountSelection">
      <v-toolbar color="#FF6969" class="text-white" dense flat>
        <v-toolbar-title>{{ $t('erp.lang_delete_order') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>

      <v-card-title v-if="!skipAmountSelection">
        {{ $t('erp.lang_QTY') }}
      </v-card-title>
      <v-card-text v-if="!skipAmountSelection">
        <v-layout style="margin-top: 40px;">
          <v-flex md1 shrink>
            <v-text-field
                v-model="itemAmount"
                class="mt-0"
                hide-details
                single-line
                style="margin-left: 10px;"
                type="number"
            />
          </v-flex>

          <v-flex md9>
            <v-slider v-model="itemAmount" :max="selectedItem.amount" min="0" style="margin-left: 20px;"
                      thumb-label="always"/>
          </v-flex>

          <v-flex md1>
            <v-btn color="primary" style="margin-left: 20px;" text
                   @click="itemAmount = selectedItem.amount">
              {{ $t('generic.lang_all') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-toolbar flat>
        <v-toolbar-title>{{ $t('erp.lang_stornogruende') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout>
          <v-flex md12>
            <v-textarea
                v-model="voidReasonInput"
                :label="$t('erp.lang_stornoGrund')"
                filled
                outlined
                autofocus
                style="margin-top: 10px;"
            />
          </v-flex>
        </v-layout>


        <v-layout>
          <v-flex md12>
            <v-btn x-small v-for="(voidReason) in voidreasons.voidReasons" :key="voidReason.id"
                   :color="voidReason.color"
                   :disabled="voidReason === selectedVoidReason" outlined
                   @click="selectVoidReason(voidReason)">{{ voidReason.name }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions class="card-footer pt-0 pb-0 pr-0 pl-0">
        <v-btn color="#FF6969" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn
            small
            elevation="0"
            class="text-white"
            :disabled="selectedVoidReason === null && voidReasonInput.length < 3 || (!skipAmountSelection && itemAmount < 1)"
            color="#FF6969"
            rounded
            @click="voidItem">{{ $t('erp.lang_delete_order') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-else v-model="dialog" max-width="700px" scrollable>
    <v-card v-if="selectedItem !== null || skipAmountSelection">
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_delete_order') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text class="pa-0">
        <v-toolbar v-if="!skipAmountSelection" flat>
          <v-toolbar-title>{{ $t('erp.lang_QTY') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="!skipAmountSelection">
          <v-layout style="margin-top: 40px;">
            <v-flex md1 shrink>
              <v-text-field
                  v-model="itemAmount"
                  class="mt-0"
                  hide-details
                  single-line
                  style="margin-left: 10px;"
                  type="number"
              />
            </v-flex>

            <v-flex md9>
              <v-slider v-model="itemAmount" :max="selectedItem.amount" min="0" style="margin-left: 20px;"
                        thumb-label="always"/>
            </v-flex>

            <v-flex md1>
              <v-btn color="primary" style="margin-left: 20px;" text
                     @click="itemAmount = selectedItem.amount">
                {{ $t('generic.lang_all') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-toolbar flat>
          <v-toolbar-title>{{ $t('erp.lang_stornogruende') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout>
            <v-flex md12>
              <v-text-field
                  v-model="voidReasonInput"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :label="$t('erp.lang_stornoGrund')"
                  filled
                  outlined
                  style="margin-top: 10px;"
                  @focus="showTouchKeyboard"
              />
            </v-flex>
          </v-layout>


          <v-layout>
            <v-flex md12>
              <v-btn v-for="(voidReason) in voidreasons.voidReasons" :key="voidReason.id"
                     :color="voidReason.color"
                     :disabled="voidReason === selectedVoidReason" outlined
                     @click="selectVoidReason(voidReason)">{{ voidReason.name }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>

        <!-- Payment TYPES -->
        <v-toolbar v-if="showPaymentTypes" flat>
          <v-toolbar-title>{{ $t('accounting.lang_repayment_method') }}</v-toolbar-title>
        </v-toolbar>

        <v-row v-if="showPaymentTypes" style="width:100%;">
          <v-col cols="12">
            <v-card tile elevation="0" class="pa-2">
              <div class="grid-menu grid-menu-3col">
                <div class="no-gutters row">
                  <div v-for="(payment,key) in filteredPayments" :key="payment.paymentID" class="col-sm-6 col-xl-4">
                    <button :style="{backgroundColor: (selectedPayment === payment.paymentID ? '#f6cdcd' : '')}"
                            @click="selectPayment(payment.paymentID)"
                            class="btn-icon-vertical btn-square btn-transition payment-btn btn h-100">
                      <i v-if="payment.paymentID===3 && (payments.ecPaymentType === 1 || payments.ecPaymentType === 2)"
                         class="btn-icon-wrapper">
                        <img :src="(payments.ecPaymentType===1)?zvt:adyen"
                             width="60%" :alt="payment.paymentName">
                      </i>
                      <i v-else class="btn-icon-wrapper">
                        <font-awesome-icon style="font-size: 40px;" :icon="['fal', (paymentIcons.hasOwnProperty(payment.paymentID))?paymentIcons[payment.paymentID]:paymentIcons[0]]"/>
                      </i>
                      <span v-if="payment.paymentID===3 && (payments.ecPaymentType === 1 || payments.ecPaymentType === 2)">
                            {{ ' ' }} </span>
                      <span v-else class="text-uppercase font-weight-bolder"
                            style="color: #515C6F !important;"> {{ payment.paymentName }} </span>
                    </button>
                  </div>
                  <div v-if="false" class="col-sm-6 col-xl-4">
                    <button :style="{backgroundColor: (selectedPayment === 104 ? '#f6cdcd' : '')}"
                            @click="selectPayment(104)"
                            class="btn-icon-vertical btn-square btn-transition payment-btn btn h-100">
                      <i class="btn-icon-wrapper">
                        <img src="@/assets/images/voffice.png" width="60%" alt="Voffice">
                      </i>

                      <span class="text-uppercase font-weight-bolder"
                            style="color: #515C6F !important;"> Voffice </span>
                    </button>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn
            :disabled="(selectedPayment === 0 && showPaymentTypes) || selectedVoidReason === null && voidReasonInput.length < 3 || (!skipAmountSelection && itemAmount < 1)"
            color="success"
            @click="voidItem">{{ $t('generic.lang_void') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard"
                            :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input"
                            :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options"
                            class="internalWidthExpanded"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#onScreenKeyboardVoid {
  z-index: 299 !important;
}
</style>

<script>
import KeyboardMixIns from "../../mixins/KeyboardMixIns";
import {mapState} from "vuex";

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCreditCard,
  faCreditCardBlank,
  faEuroSign,
  faHandsHeart,
  faLineColumns,
  faPiggyBank,
  faReceipt
} from '@fortawesome/pro-light-svg-icons';

library.add(
    faCreditCard,
    faCreditCardBlank,
    faHandsHeart,
    faEuroSign,
    faPiggyBank,
    faReceipt,
    faLineColumns
);

export default {
  name: "VoidReasons",

  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    selectedItem: {
      type: Object,
      default: null
    },
    skipAmountSelection: {
      type: Boolean,
      default: false
    },
    showPaymentTypes: Boolean,
    invoice: Object,
    isCMI: Boolean,
    isFullVoid: Boolean
  },

  data() {
    return {
      selectedVoidReason: null,
      voidReasonInput: "",
      itemAmount: 1,
      paymentIcons: {
        0: 'piggy-bank',
        1: 'euro-sign',
        3: 'credit-card',
        4: 'receipt',
        6: 'hands-heart',
        7: 'line-columns',
        9: 'credit-card-blank'
      },
      adyen: require("@/assets/images/ecterminal/adyen-logo-green.svg"),
      zvt: require("@/assets/images/ecterminal/ZVT.svg"),
      selectedPayment: 0,
      dialog: false,
    }
  },

  computed: {
    ...mapState([
      'voidreasons',
      'payments',
    ]),
    filteredPayments() {
      return this.payments.payments.filter((payment) => {
        if(payment.paymentID === 3 || payment.paymentID === 9){
          if(this.isCMI)
            return this.isFullVoid;
        }

        if(payment.paymentID === 7)
          if(!this.isFullVoid)
            return false;

        return true;
      });
    }
  },

  watch: {
    voidReasonInput(value) {
      console.log(this.isCMI)
      if (value !== "")
        this.selectedVoidReason = null;
    },
    showDialog(val) {
      this.dialog = val;
      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        //CHECK PAYMENT TYPE
        if (this.showPaymentTypes) {
          let paymentID = this.invoice.invoiceData.typeOfPayment;

          let payment = this.payments.payments.find((payment) => payment.paymentID === paymentID);

          if (!payment) {
            this.selectedPayment = 1;
          } else {
            // CHECK SPLIT PAYMENT
            if(paymentID === 7 && !this.isFullVoid) {
              this.selectedPayment = 1;
              return;
            }

            this.selectedPayment = paymentID;
          }
        }

        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    dialog(v){
      if(!v){
        this.hideTouchKeyboard();
        this.closeDialog();
      }
    }
  },

  methods: {
    selectPayment(paymentID) {
      if (paymentID === this.selectedPayment) {
        this.selectedPayment = 0;
      } else {
       // console.log(paymentID);
        this.selectedPayment = paymentID;
      }
    },
    closeDialog() {
      this.$emit("closeDialog");

      this.selectedVoidReason = null;
      this.voidReasonInput = "";
      this.selectedPayment = 0;
    },
    selectVoidReason(voidReason) {
      this.selectedVoidReason = voidReason;
      this.voidReasonInput = "";
    },
    voidItem() {
      let voidReason;
      let id;
      //VOID REASON
      if (this.selectedVoidReason != null) {
        voidReason = this.selectedVoidReason.name;
        id = this.selectedVoidReason.id
      } else {
        voidReason = this.voidReasonInput;
        id = 0;
      }

      //VOID ITEM AMOUNT
      let amount;

      if (this.skipAmountSelection) {
        //SLIDERS ARE HIDDEN (NORMALLY WHEN JUST GETTING VOID REASON, FOR CREATE RETOUR INVOICE)
        //BUT IT COULD BE, THAT ITEM HAS WEIGHT AMOUNT, THEN USE FULL ITEM AMOUNT AND SLIDERS ARE ALSO HIDDEN
        if (this.selectedItem !== null) {
          amount = this.selectedItem.amount;
        } else {
          amount = 1;
        }
      } else {
        //USE AMOUNT FROM SLIDERS (USER INPUT)
        amount = this.itemAmount;
      }

      this.$emit("selectVoidReason", {
        id: id,
        voidReason: voidReason,
        amount: amount,
        paymentID: this.selectedPayment,
      });

      this.selectedVoidReason = null;
      this.voidReasonInput = "";
      this.selectedPayment = 0;
    }
  },
}
</script>