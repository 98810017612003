<template>
  <v-dialog v-model="showDialog" persistent width="500">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular indeterminate color="primary" width="3" size="50" />

              <p style="margin-top: 10px; font-weight: bold; margin-bottom: 0;">{{
                $t('generic.lang_waitingForEcPaymentCMI') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="!showProceedButton && !isCancelling" color="error" @click="cancelDialog()">
          {{ $t('generic.lang_cancelPayment') }}
        </v-btn>
        <!-- <v-spacer></v-spacer>
        <v-btn color="success" :disabled="!showProceedButton" @click="cancelPayment()">{{
          $t('generic.lang_paymentSuccessful') }}
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Events } from "@/plugins/events";
import { debounce } from 'lodash';

export default {
  name: "CashoutCMIDialog",
  props: {
    void: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: false,
      isCancelling: false,
      showProceedButton: false,
      cancelTimeout: null,
      cancelSource: null,
      amount: 0
    }
  },

  methods: {
    makePayment(amount) {
      this.amount = amount;

      this.showDialog = true;

      const CancelToken = this.axios.CancelToken;
      this.cancelSource = CancelToken.source();

      //MAKE REQUEST
      this.axios.post("3rdPartyIntegrations/cmi/create/paymentRequest/", {
        paymentAmount: parseInt(this.amount * 100),
      }, {
        cancelToken: this.cancelSource.token
      }).then((res) => {
        if (res.status === 200) {
          this.showDialog = false;

          if (res.data.response_message === "SUCCESS") {
            this.finishPayment(res.data);
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.response_message,
              timeout: 5000,
              color: "error"
            });

            this.cancelDialog();
            
          }
        }else {
          this.cancelDialog();
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_ecPaymentError') + " (" + err.message + ")",
          timeout: 5000,
          color: "error"
        });

        this.resetDialog();
        this.$emit("cancel");
      });
    },
    makeVoid(ecInvoiceId, amount) {
      this.amount = amount;
      this.showDialog = true;

      const CancelToken = this.axios.CancelToken;
      this.cancelSource = CancelToken.source();

      //MAKE REQUEST
      this.axios.post("3rdPartyIntegrations/cmi/create/voidRequest/", {
        invoiceID: ecInvoiceId,
      }, {
        cancelToken: this.cancelSource.token
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.response_message === "SUCCESS") {
            this.finishPayment(res.data);
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.response_message,
              timeout: 5000,
              color: "error"
            });
            this.cancelDialog()
            // this.checkStatus();
          }
        }else {
          this.cancelDialog();
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_ecPaymentError') + " (" + err.message + ")",
          timeout: 5000,
          color: "error"
        });

        this.cancelDialog();
      });
    },
    checkStatus() {

      this.showDialog = true;
      //MAKE REQUEST
      this.axios.post("3rdPartyIntegrations/cmi/get/paymentCheck/", {
        paymentAmount: parseInt(this.amount * 100),
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.response_message === "SUCCESS") {
            this.finishPayment(res.data);
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.response_message,
              timeout: 5000,
              color: "error"
            });

            this.cancelDialog();
          }
        }else {
          this.cancelDialog();
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_ecPaymentError') + " (" + err.message + ")",
          timeout: 5000,
          color: "error"
        });
        this.resetDialog();
      });
    },
    cancelDialog(data) {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_paymentError') + "!",
        timeout: 5000,
        color: "error"
      });
      this.cancelSource?.cancel();
      this.resetDialog();
      this.$emit("cancel", data);
    },
    finishPayment(data) {
      this.amout = 0;
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_paymentMade'),
        timeout: 5000,
        color: "success"
      });

      if(data?.msg == 'skipped'){
        this.$emit("finished", data);
        this.resetDialog();
        return;
      }
      
      data.printing ={
        customer: null,
        salesperson: null,
      }
      data.printing.customer = this.parseTerminalData(data.customer_receipt);
      data.printing.salesperson = this.parseTerminalData(data.merchant_receipt);

      const  {customer_receipt,merchant_receipt,...ecData} = data;
       

      this.$emit("finished", ecData);
      this.resetDialog();
    },

    parseTerminalData(terminalData) {
      const printObj = [];
      let currentAlign = 'left';
      let currentBold = false;

      terminalData.forEach(obj => {
        // Handle special CUT command
        if (obj.align === 'CUT') {
          if (/^\s*$/.test(obj.text)) {
            // printObj.push({ feed: 1 });
          }
          // printObj.push({ cut: true });
          return;
        }

        // // Determine new formatting
        // const newAlign = (obj.align || 'LEFT').toLowerCase();
        // const newBold = obj.style === 'BOLD';

        // // Update alignment if changed
        // if (newAlign !== currentAlign) {
        //   printObj.push({ align: newAlign });
        //   currentAlign = newAlign;
        // }

        // // Update bold if changed
        // if (newBold !== currentBold) {
        //   printObj.push({ bold: newBold });
        //   currentBold = newBold;
        // }

        // Handle text content
        const text = obj.text+' ';
        if (/^\s*$/.test(text.trim())) {
          // Empty or whitespace-only text becomes feed
          // printObj.push({ feed: 1 });
          printObj.push(text+' \n');
        } else {
          // Add text instruction
          printObj.push(text);

          // Add line feed if text doesn't end with newline
          if (text.endsWith('\n')) {
            printObj.push(text+' \n');
          }
        }
      });

      return printObj;
    },

    resetDialog() {
      this.showDialog = false;
      this.showProceedButton = false;
      this.showProceedButton = false;
      this.isCancelling = false;

      clearTimeout(this.cancelTimeout);
    }
  }
}
</script>