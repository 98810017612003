<template>
  <div>
  <v-dialog v-model="showDialog" scrollable persistent max-width="800">
    <v-card :disabled="loadingVoid">
      <v-toolbar color="#424242" dark dense flat>
        <v-toolbar-title>{{ $t('generic.lang_retour_checkout') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="height: 600px;">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-card-title>{{ $t('generic.lang_cashierReceipt') }}</v-card-title>

                <v-list v-if="invoiceItems.length > 0">
                  <v-list-item
                      :disabled="invoiceItem.isVoid || invoiceItem.ean.includes('V$') || invoiceItem.ean.includes('GF$') || invoiceItem.ean.includes('ZMYLE$') || invoiceItem.ean.includes('MYWORLD$') || invoiceItem.ean.startsWith('DEPOSIT')"
                      :style="{backgroundColor: (invoiceItem.isVoid && !invoiceItem.ean.startsWith('DEPOSIT') ? '#ffb3bd' : invoiceItem.ean.startsWith('DEPOSIT') ? '#FFF2BB' : '')}"
                      @click="selectInvoiceItem(invoiceItem, index)" two-line
                      v-for="(invoiceItem, index) in invoiceItems" :key="invoiceItem.randomPositionID">
                    <v-list-item-content>
                      <v-list-item-title>{{ formatQty(invoiceItem.qty) }} {{ invoiceItem.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ invoiceItem.sellPrice | currency }} |
                        {{ (invoiceItem.sellPrice * invoiceItem.qty) | currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card flat>
                <v-card-title>{{ $t('generic.lang_retour_checkout') }}</v-card-title>

                <v-list v-if="retoureItems.length > 0">
                  <v-list-item :disabled="retoureItem.isVoid || retoureItem.ean.startsWith('DEPOSIT')"
                               :style="{backgroundColor: (retoureItem.isVoid && !retoureItem.ean.startsWith('DEPOSIT') ? '#ffb3bd' : retoureItem.ean.startsWith('DEPOSIT') ? '#FFF2BB' : '')}"
                               @click="selectRetoureItem(retoureItem, index)" two-line
                               v-for="(retoureItem, index) in retoureItems" :key="retoureItem.randomPositionID">
                    <v-list-item-content>
                      <v-list-item-title>{{ formatQty(retoureItem.qty) }} {{ retoureItem.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ retoureItem.sellPrice | currency }} |
                        {{ (retoureItem.sellPrice * retoureItem.qty) | currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="!showVoidAllButton" @click="voidAllInvoiceItems">
          {{ $t('generic.lang_voidAll') }}
        </v-btn>
        <v-btn color="success" @click="showVoidReasonsDialog = true" :loading="loadingVoid"
               :disabled="retoureItems.length === 0 || loadingVoid">{{ $t('generic.lang_void') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="400">
      <v-card>
        <v-card-title>{{ $t('generic.lang_info') }}</v-card-title>
        <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-btn v-if="showReprintButton" text color="error" @click="closeErrorDialog">{{
              $t('generic.lang_cancel')
            }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!showReprintButton" text color="primary" @click="closeErrorDialog">{{
              $t('generic.lang_next')
            }}
          </v-btn>
          <v-btn v-if="showReprintButton" text color="primary" @click="reprintInvoiceRetoure">
            {{ $t('generic.lang_retry') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <VoidReasons skip-amount-selection :show-dialog="showVoidReasonsDialog" @closeDialog="showVoidReasonsDialog = false" :isCMI="payments.ecPaymentType === 4"
                 @selectVoidReason="voidReasonSelected" show-payment-types 
                 :is-full-void="isFullVoid && (payments.ecPaymentType === 4? invoice?.invoiceData?.cashierID === api.auth.cashierID : true)" 
                 :invoice="invoice"/>



  </v-dialog>

  <CashoutZVTDiaog ref="zvtDialog" @finished="zvtFinished"
                   @cancel="loadingVoid = false"></CashoutZVTDiaog>
  <CashoutCMIDialog ref="cmiDialog" @finished="cmiFinished" :void="true"
                   @cancel="loadingVoid = false"></CashoutCMIDialog>
</div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {mapGetters, mapState} from "vuex";
import {createRetourePrintingData} from "@/plugins/printing/printRetoure";
import CashoutZVTDiaog from "@/components/pos/CashoutZVTDiaog";
import VoidReasons from "./VoidReasons";
import {Events} from "@/plugins/events";
import {printDataFromPrinter} from "../../plugins/printing/printerController";
import {geol} from "@/main";
import CashoutCMIDialog from "./CashoutCMIDialog.vue";

export default {
  name: "RetourDialog",

  components: {
    VoidReasons,
    CashoutCMIDialog,
    CashoutZVTDiaog
  },

  props: {
    showDialog: Boolean,
    posType: String,
    invoice: Object
  },

  data() {
    return {
      loadingVoid: false,
      invoiceItems: [],
      retoureItems: [],
      errorDialogMessage: "",
      errorDialogCloseAction: null,
      showReprintButton: false,
      reprintPayload: null,
      showVoidReasonsDialog: false,
      tseTransaction: null,
      ecPaymentRun: 0,
      ecPaymentMsg: null,
      voidPayload: null
    }
  },

  watch: {
    showDialog(val) {
      if (val) {
        if (this.invoice !== null) {
          let invoiceItems = [];

          this.invoice.invoicePositionData.forEach((invoicePosition) => {
            //CHECK IF VOIDED
            let isVoid = false;

            if (invoicePosition.isVoided === 1 && invoicePosition.voidedQty === 0) {
              //VOID ITEM CREATED WHILE MAKING SALE
              isVoid = true;
            } else if (invoicePosition.isVoided === 1 && invoicePosition.qty === invoicePosition.voidedQty) {
              isVoid = true;
            } else {
              isVoid = false;
            }

            invoiceItems.push({
              position: invoicePosition.pos,
              qty: (invoicePosition.voidedQty < invoicePosition.qty ? (invoicePosition.qty - invoicePosition.voidedQty) : invoicePosition.voidedQty),
              voidedQty: invoicePosition.voidedQty,
              sellPrice: invoicePosition.unitPrice,
              name: invoicePosition.descr,
              isVoid: isVoid, // (invoicePosition.qty === invoicePosition.voidedQty && invoicePosition.isVoided === 1),
              ean: invoicePosition.ean,
              taxValue: invoicePosition.tax,
              depositId: invoicePosition.depositId
            });

            //CREATE -VIRTUAL- POSITION WHEN NOT FULL QTY WAS VOID
            if (invoicePosition.voidedQty > 0 && invoicePosition.voidedQty !== invoicePosition.qty) {
              invoiceItems.push({
                position: invoicePosition.pos,
                qty: invoicePosition.voidedQty,
                voidedQty: invoicePosition.voidedQty,
                sellPrice: invoicePosition.unitPrice,
                name: invoicePosition.descr,
                isVoid: true,
                ean: invoicePosition.ean,
                taxValue: invoicePosition.tax,
                depositId: invoicePosition.depositId
              });
            }
          });

          this.invoiceItems = invoiceItems;
        }
      } else {
        this.invoiceItems = [];
        this.retoureItems = [];
        this.loadingVoid = false;
        this.errorDialogMessage = "";
        this.showReprintButton = false;
        this.reprintPayload = null;
      }
    }
  },

  computed: {
    ...mapState([
      'pos',
      'api',
      'settings',
      'posLayoutTemplates',
      'user',
      'payments',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
      activeUser: 'multiUser/activeUser',
    }),
    retourePositions() {
      return this.retoureItems.map((item) => {
        return {
          // isVoid: parseInt(item.isVoid) === 1,
          position: item.position,
          qty: item.qty
        };
      })
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    showVoidAllButton() {
      let voidableItem = this.invoiceItems.find((invoiceItem) => {
        return !invoiceItem.isVoid;
      });

      if (!voidableItem)
        return false;

      if (this.retoureItems.length > 0)
        return false;

      return true;
    },
    isFullVoid() {
      return this.invoiceItems.length === 0
    },
    multiUser() {
      if (this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

        if (templateID > 0) {
          let template = this.posLayoutTemplates.templates.some(template => {
            if (templateID === template.id) {
              return (template.multiUser === 1 || template.multiUser === true)
            }
          });
          return template;
        }
      }
      return false;
    },
    activeUserID() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return parseInt(this.activeUser.userID);

      return this.user.userID;
    },
    splitPayment() {
      if(this.invoice === null)
        return [];

      if(!this.showDialog)
        return [];

      if(!this.invoice.hasOwnProperty("invoiceData"))
        return [];

      // CONVERT SPLIT PAYMENTS
      let splitPaymentArray = [];

      if(this.invoice.invoiceData.typeOfPayment === 7)
        for(let paymentID in this.invoice.invoiceData.splitPaymentObj) {
          if(!this.invoice.invoiceData.splitPaymentObj.hasOwnProperty(paymentID))
            continue;

          splitPaymentArray.push({
            id: parseInt(paymentID),
            amount: this.invoice.invoiceData.splitPaymentObj[paymentID] * -1
          });
        }

      return splitPaymentArray;
    }
  },

  mounted() {
    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    selectInvoiceItem(item, itemIndex) {
      //CHECK IF ITEM IS VOUCHER
      if (item.ean.includes("V$") || item.ean.includes("GF$") || item.ean.includes("ZMYLE$") || item.ean.includes("MYWORLD$"))
        return;

      //CHECK IF WE HAVE TO START TSE TRANSACTION
      if (this.tseTransaction === null && this.fiscalClient !== null)
        this.startTransaction();

      let retoureItem = this.retoureItems.find((retoureItem) => {
        return retoureItem.position === item.position;
      });

      //CALC AMOUNT TO CHANGE -> WE NEED TO DO THIS BECAUSE AMOUNT CAN ALSO BE WEIGHT, ETC.
      let addAmount = 1;

      /*
      if(item.qty < 1 && item.qty > 0)
          addAmount = item.qty;
      */

      // check if qty is 0 so we don't need to add by 1
      if(item.qty === 0)
        addAmount = item.qty;

      if (!Number.isInteger(item.qty))
        addAmount = item.qty;

      //CHECK IF RETOURE ITEM ALREADY EXISTS
      if (!retoureItem) {
        this.retoureItems.push({
          position: item.position,
          name: item.name,
          sellPrice: item.sellPrice,
          qty: addAmount,
          voidedQty: item.voidedQty,
          ean: item.ean,
          taxValue: item.taxValue,
          isVoid: item.isVoid,
          depositId: item.depositId
        });
      } else {
        retoureItem.qty += addAmount;
        //retoureItem.qty = this.roundToTwo(retoureItem.qty);
      }

      //CHECK AMOUNT
      if (item.qty > 1 && item.qty !== addAmount) {
        item.qty -= addAmount;
        //item.qty = this.roundToTwo(item.qty);
      } else {
        this.$delete(this.invoiceItems, itemIndex);
      }

      //CHECK IF ITEM NOT DEPOSIT and AUTO VOID ITS DEPOSIT
      if (!item.ean.startsWith("DEPOSIT") && item.depositId != 0) {
        let invoiceItem = this.invoiceItems.find((invoiceItem) => {
          return invoiceItem.ean === `DEPOSIT${item.depositId}` && !invoiceItem.isVoid;
        });

        if (invoiceItem) {
          let itemIndex = this.invoiceItems.indexOf(invoiceItem);
          this.selectInvoiceItem(invoiceItem, itemIndex);
        }
      }
    },
    selectRetoureItem(item, itemIndex) {
      let invoiceItem = this.invoiceItems.find((invoiceItem) => {
        return invoiceItem.position === item.position && !invoiceItem.isVoid;
      });

      //CALC AMOUNT TO CHANGE -> WE NEED TO DO THIS BECAUSE AMOUNT CAN ALSO BE WEIGHT, ETC.
      let addAmount = 1;

      /*
      if(item.qty < 1 && item.qty > 0)
          addAmount = item.qty;
      */

      if(item.qty===0)
        addAmount = item.qty;

      if (!Number.isInteger(item.qty))
        addAmount = item.qty;

      //CHECK IF INVOICE ITEM ALREADY EXISTS
      if (!invoiceItem) {
        this.invoiceItems.push({
          position: item.position,
          name: item.name,
          sellPrice: item.sellPrice,
          qty: addAmount,
          voidedQty: item.voidedQty,
          ean: item.ean,
          taxValue: item.taxValue,
          isVoid: item.isVoid,
          depositId: item.depositId
        });
      } else {
        invoiceItem.qty += addAmount;
        //invoiceItem.qty = this.roundToTwo(invoiceItem.qty);
      }

      //CHECK AMOUNT
      if (item.qty > 1 && item.qty !== addAmount) {
        item.qty -= addAmount;
        //item.qty = this.roundToTwo(item.qty);
      } else {
        this.$delete(this.retoureItems, itemIndex);
      }

      //CHECK IF ITEM NOT DEPOSIT and AUTO VOID ITS DEPOSIT
      if (!item.ean.startsWith("DEPOSIT") && item.depositId != 0) {
        let retoureItem = this.retoureItems.find((retoureItem) => {
          return retoureItem.ean === `DEPOSIT${item.depositId}` && !retoureItem.isVoid;
        });

        if (retoureItem) {
          let itemIndex = this.retoureItems.indexOf(retoureItem);
          this.selectRetoureItem(retoureItem, itemIndex);
        }
      }
    },
    voidAllInvoiceItems() {
      //CHECK IF WE HAVE TO START TSE TRANSACTION
      if (this.tseTransaction === null && this.fiscalClient !== null)
        this.startTransaction();

      //SET ALL INVOICE ITEMS AS RETOURE ITEM EXCEPT VOIDED ITEMS
      this.retoureItems = this.invoiceItems.filter((invoiceItem) => {
        if (invoiceItem.isVoid)
          return;

        if (invoiceItem.ean.includes("V$") || invoiceItem.ean.includes("GF$") || invoiceItem.ean.includes("ZMYLE$") || invoiceItem.ean.includes("MYWORLD$"))
          return;

        return invoiceItem;
      });

      //REMOVE ALL INVOICEITEMS EXCEPT VOIDED ITEMS
      this.invoiceItems = this.invoiceItems.filter((invoiceItem) => {
        if (!invoiceItem.isVoid && !invoiceItem.ean.includes("V$") && !invoiceItem.ean.includes("GF$") && !invoiceItem.ean.includes("ZMYLE$") && !invoiceItem.ean.includes("MYWORLD$"))
          return;

        return invoiceItem;
      });
    },
    voidReasonSelected(payload) {
      //CLOSE DIALOG
      this.showVoidReasonsDialog = false;

      //MAKE API CALL
      this.voidItems(payload);
    },

    startTransaction() {
      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (tseDevice !== null) {
        if (!tseDevice.tseReady) {
          return;
        }

        // SINGLE CASHIER MODE
        tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

        Events.$once("GFE_OnReceive", (payload) => {
          if (payload.result.function === "StartTransaction") {
            if (payload.result.result === "EXECUTION_OK") {
              this.tseTransaction = payload.result.output;
            }
          }
        });
      }
    },

    // --- API CALL ---
    voidItems(payload) {
      this.loadingVoid = true;
      this.voidPayload = payload;
      let needECPayment = false;



      if (payload.paymentID === 3 || payload.paymentID === 9) {
        needECPayment = true;
      }

      if (needECPayment) {
        //ZVT
        if (this.payments.ecPaymentType === 1 && this.ecPaymentRun === 0) {
          let ecInvoiceId = parseInt(this.invoice.invoiceData.ecTermReceiptId);


          /* GET RETURNED AMOUNT FOR ZVT */
          let zvtreturn = this.retoureItems;
          let zvt_reversal_amount = 0.00;
          zvtreturn.forEach((element, index) => {
            zvt_reversal_amount += element.qty * element.sellPrice;
          });

          this.$refs.zvtDialog.reversalPayment(ecInvoiceId,zvt_reversal_amount);
          return;
        }

        //CMI
        if (this.payments.ecPaymentType === 4 && this.ecPaymentRun === 0 && this.isFullVoid ) {
          this.$refs.cmiDialog.makeVoid(this.invoice.invoiceData.invoiceUUID, Number(this.invoice.invoiceData.invoiceTotal));
          return;
        }

      }

      this.axios.post(ENDPOINTS.POS.RETURNSALE.CREATE, {
        returnSaleID: this.invoice.invoiceData.invoiceUUID, // f5eef09a-497a-11ea-8bb6-0a1c9e06025b
        retourType: 1,
        retourPositions: this.retourePositions,
        voidReason: payload.voidReason,
        voidReasonID: payload.id,
        typeOfPayment: payload.paymentID,
        ecPaymentMsg: this.ecPaymentMsg,
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          this.checkTSE(res.data);
        } else {
          this.loadingVoid = false;
          this.errorDialogMessage = this.$t('generic.lang_fehler') + ": " + res.data.msg;
        }
      }).catch(() => {
        this.loadingVoid = false;
        this.errorDialogMessage = this.$t('generic.lang_invoiceCouldNotBeCancelled');
        this.errorDialogCloseAction = null;
      });
    },

    zvtFinished(axiosPayload) {
      this.ecPaymentRun = 1;
      this.ecPaymentMsg = axiosPayload;
      this.voidItems(this.voidPayload)
    },
    
    cmiFinished(axiosPayload) {
      this.ecPaymentRun = 1;
      this.ecPaymentMsg = axiosPayload;
      
      this.voidItems(this.voidPayload)
    },

    checkTSE(res) {
      try {
        //CHECK IF FISCAL CLIENT IS SETUP
        if (this.fiscalClient === null) {
          this.printWithOutTSE(res);
          return;
        }

        if (this.fiscalClient.device.type === null) {
          this.fiscalClient.device.type = "epsonTSE";
        }

        //FISKALY CLOUD TSE
        if (this.fiscalClient.device.type === "fiskaly") {
          this.printWithOutTSE(res);
          return;
        }

        //GET TSE DEV
        let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

        if (!tseDevice.tseReady) {
          this.printWithOutTSE(res);
          return;
        }

        //RE-MAP RETOURE ITEMS (we use amount instead of qty)
        let retoureItems = this.retoureItems.map((retoureItem) => {
          retoureItem.amount = retoureItem.qty;
          retoureItem.sellPrice = retoureItem.sellPrice * -1;
          retoureItem.isVoid = false; // WE SET IT ALWAYS FALSE, ELSE ePosTSEPrinterClass will ignore position // retoureItem.isVoided === 1;

          return retoureItem;
        });

        tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, this.tseTransaction, retoureItems, this.invoice.invoiceData.typeOfPayment, this.activeUserID, this.splitPayment);

        Events.$once("GFE_OnReceive", (payload) => {
          if (payload.result.function === "FinishTransaction") {
            if (payload.result.result === "EXECUTION_OK") {
              this.printRetourInvoice(res, payload, tseDevice, retoureItems)
            }
          }
        });
      } catch (error) {
        this.errorDialogMessage = this.$t('generic.lang_tseIsNotOperational');
        this.errorDialogCloseAction = null;
        this.printWithOutTSE(res);
        this.loadingVoid = false;
      }
    },
    async printRetourInvoice(payload, tsePayload, tseDevice, retoureItems) {
      //GET PRINTING XML DATA
      let printData = createRetourePrintingData(payload.invoicePositions, {
        saleID: payload.invoiceID,
        tableId: payload.tableId,
        total: 0,
        cashierID: payload.cashierID,
        userID: payload.userID,
        ts: payload.timestamp
      }, this.invoicePrinter.paperType);

      //FIND LAST CUT OF PRINT DATA(INDEX)
      let lastCutIndex = null;

      printData.forEach((element, index) => {
        if (element.hasOwnProperty("cut"))
          if (element.cut)
            lastCutIndex = index;
      });

      //DELETE LAST CUT, BECAUSE WE WILL ADD CUT AFTER TSE DATA
      if (lastCutIndex !== null)
        this.$delete(printData, lastCutIndex);

      //ADD TSE PRINTING DATA
      let result = tsePayload.result;

      printData.push({
        feed: 1
      });

      printData.push({
        fontSize: 1
      });

      printData.push({
        font: "B"
      });

      printData.push({
        text: geol.t('accounting.lang_eazSerNr') + ": " + this.api.auth.posID + " - " + this.api.auth.cashierID
      });
      printData.push({
        text: geol.t('accounting.lang_tseSerNr') + ": " + tseDevice.storageInfo.tseInformation.serialNumber
      });
      printData.push({
        text: geol.t('accounting.lang_tseStart') + ": " + this.tseTransaction.logTime
      });
      printData.push({
        text: geol.t('accounting.lang_tseStop') + ":  " + result.output.logTime
      });
      printData.push({
        text: geol.t('accounting.lang_tseTrxNr') + ": " + this.tseTransaction.transactionNumber
      });
      printData.push({
        text: geol.t('accounting.lang_tseSigCount') + ":" + result.output.signatureCounter
      });
      printData.push({
        text: geol.t('accounting.lang_signatur') + ":  " + result.output.signature
      });

      let qrCode = tseDevice.createInvoiceQRCode(retoureItems, this.invoice.invoiceData.typeOfPayment, this.activeUserID, this.splitPayment, this.tseTransaction.transactionNumber, result.output.signatureCounter, this.tseTransaction.logTime, result.output.logTime, result.output.signature, this.fiscalClient.clientID);

      printData.push({
        align: "center"
      });

      //ADD QR CODE
      printData.push({
        qrCode: qrCode
      });

      printData.push({
        cut: true
      });

      // SAVE FISCAL DATA
      try {
        let type;

        if (this.activeUserID !== 101)
          type = "Beleg";
        else
          type = "AVTraining";

        let savePayload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            type: type,
            startTime: this.pos.retail.transactionPayload.logTime,
            stopTime: result.output.logTime,
            transactionNumber: this.pos.retail.transactionPayload.transactionNumber,
            signatureCounter: result.output.signatureCounter,
            signature: result.output.signature
          },
          qrCode: qrCode
        };

        await this.axios.post(ENDPOINTS.POS.SALE.UPDATETSEPAYLOAD, {
          invoiceUUID: payload.invoiceRetourID,
          tsePayload: savePayload
        });
      }
      catch(error) {
        console.log("cashoutTSEMixin: error saving tse fiscal data to invoice", error);
      }
      printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
        this.closeDialog();
      }).catch((err)=>{
        this.errorDialogMessage = this.$t('generic.lang_printReceiptError');
        this.errorDialogCloseAction = null;
      }).finally(()=>{
        this.loadingVoid = false;
      })


    },
    addZVTPrinting(printData) {
      // --------------------------- EC PRINTING --------------------------
      if (this.ecPaymentMsg.hasOwnProperty("printing")) {
        if (this.ecPaymentMsg.printing.hasOwnProperty("customer")) {
          // printData.push({cut: true});
          // REMOVE LAST CUT
          if(this.ecPaymentMsg.printing.customer.length > 1) {
            let lastElement = printData[(printData.length - 1)];
            if (!lastElement.hasOwnProperty("cut"))
              this.$delete(printData, (printData.length - 1));

            printData.push({align: 'center'});
            printData.push({feed: 2});

            // LOOP EC PRINTING DATA
            this.ecPaymentMsg.printing.customer.forEach((printElement) => {
              if (printElement.trim().length === 0)
                return;

              printData.push({text: printElement});
            });

            printData.push({feed: 4});

          }
        }

        // HÄNDLERBELEG
        if (this.ecPaymentMsg.printing.hasOwnProperty("salesperson")) {
          printData.push({cut: true});
          if(this.ecPaymentMsg.printing.salesperson.length > 1) {
            printData.push({feed: 2});

            // LOOP EC PRINTING DATA
            this.ecPaymentMsg.printing.salesperson.forEach((printElement) => {
              if (printElement.trim().length === 0)
                return;

              printData.push({text: printElement});
            });

            printData.push({feed: 4});
            printData.push({cut: true});
          }
        }
      }
      // ---------------------------------------------------------------

      return printData;
    },
    printWithOutTSE(payload) {
      //GET PRINTING XML DATA
      let printData;

      try {
        printData = createRetourePrintingData(payload.invoicePositions, {
          saleID: payload.invoiceID,
          total: 0,
          cashierID: payload.cashierID,
          userID: payload.userID,
          ts: payload.timestamp
        }, this.invoicePrinter.paperType);

        //CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[(printData.length - 1)];




        let fiskalQrCode = payload.fiskalQRCode;
        let fiskalData = payload.fiskalData;

        if (fiskalData !== null) {
          printData.push({
            fontSize: 1
          });

          printData.push({
            font: "B"
          });
          printData.push({
            text: geol.t('accounting.lang_eazSerNr') + ": " + fiskalData.client_id
          });
          printData.push({
            text: geol.t('accounting.lang_tseSerNr') + ": " + fiskalData.tss_serial_number
          });
          printData.push({
            text: geol.t('accounting.lang_tseStart') + ": " + fiskalData.time_start
          });
          printData.push({
            text: geol.t('accounting.lang_tseStop') + ":  " + fiskalData.time_end
          });
          printData.push({
            text: geol.t('accounting.lang_tseTrxNr') + ": " + fiskalData.number
          });
          printData.push({
            text: geol.t('accounting.lang_tseSigCount') + ":" + fiskalData.signature.counter
          });
          printData.push({
            text: geol.t('accounting.lang_signatur') + ":  " + fiskalData.signature.value
          });

          printData.push({
            align: "center"
          });

          //ADD QR CODE
          printData.push({
            qrCode: fiskalQrCode
          });
        }

        //REMOVE LAST CUT FROM EC PRINTING
        if(this.ecPaymentMsg === null) {
          if (!lastElememt.hasOwnProperty("cut")) {
            printData.push({cut: true});
          }
        }

        // ADD ZVT PRINTING
        if(this.ecPaymentMsg !== null) {
          printData = this.addZVTPrinting(printData);
        }


      } catch (error) {
        console.log(error)
        this.errorDialogMessage = this.$t('generic.lang_receiptPrintoutCouldNotBeCreated');
        this.errorDialogCloseAction = "finish";
        this.showReprintButton = false;
        return;
      }

      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
        this.closeDialog();
      }).catch((err)=>{

        this.errorDialogMessage = this.$t('generic.lang_printReceiptError');
        this.errorDialogCloseAction = null;
        this.reprintPayload = payload;
        this.showReprintButton = true;
      }).finally(()=>{
        this.loadingVoid = false;
      })



    },
    reprintInvoiceRetoure() {
      this.loadingVoid = true;
      this.errorDialogMessage = "";

      this.printWithOutTSE(this.reprintPayload);
    },
    closeErrorDialog() {
        this.closeDialog();

      this.errorDialogMessage = "";
    },
    formatQty(qty) {
      if (Number.isInteger(qty))
        return parseInt(qty) + "x";

      return this.roundToTwo(qty);
    },
    roundToTwo(value) {
      return +(Math.round(value + "e+2") + "e-2");
    }
  }
}
</script>